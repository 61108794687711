import { useRequesterStore } from '@/stores/requester';
import { useUtilsStore } from "@/stores/utils";
import { useBinarySongStore } from "@/stores/binarySongs";
import { useUserStore } from "@/stores/user";
export function useBackgroundFetch() {
    const requesterX = useRequesterStore();
    const utilsX = useUtilsStore();
    const binarySongX = useBinarySongStore();
    const userX = useUserStore();
    const storageDir = "6kgxq5l95dNVvpb1";
    const downloadSong = (song) => {
        const {id,num} = song;
        
        if(userX.localSongs.includes(`ECK ${num}.mp3`)){
            utilsX.setNotif({
                title: "song.storage",
                message: "song.alreadyDownloaded",
                type: "info",
                timeVisible: 10,
                position: "top-center",
            });
            return;
        }
        navigator.serviceWorker.ready.then(async (swReg) => {
            try{
                const songDetail = await requesterX.Get({
                    route: `/song-detail/${id}?type=download`,
                    withAuth: true,
                    localAvailable: false
                });
    
                const { jwt, song } = songDetail.data;
    
                const songsUrl = `${import.meta.env.VITE_PLAYER_URL}/song-play`;
                const voiceUrl = `${import.meta.env.VITE_PLAYER_URL}/song-voice`
                const files = !song.hasVoice?[`${songsUrl}/ECK%20${song.num}.mp3?auth=${jwt}`]:[`${songsUrl}/ECK%20${song.num}.mp3?auth=${jwt}`,
                    `${voiceUrl}/${song.num}.mp3?auth=${jwt}`
                ]
                
                if ("BackgroundFetchManager" in self) {
                    
                    swReg.backgroundFetch.fetch(
                        id,
                        files,
                        {
                            title: song.title,
                            icons: [
                                {
                                    sizes: "300x300",
                                    src: '/images/offline-default/0.webp',
                                    type: "image/webp",
                                },
                            ],
                            //downloadTotal: 60 * 1024 * 1024,
                        },
                    ).then(async function(fetch) {
                        // Handle success
                        const stream = await fetch.matchAll();
                        const record = stream[0];
                        const response = await record.responseReady;
                        const arrayBuffer = await response.arrayBuffer();
                        await saveFile(arrayBuffer, `ECK ${song.num}.mp3`);
        
                        if(song.hasVoice){
                            //download voice
                            const voice = stream[1];
                            const responseVoice = await voice.responseReady;
                            const arrayBufferVoice = await responseVoice.arrayBuffer();
                            await saveFile(arrayBufferVoice, `${song.num}.mp3`);
                        }
                        await requesterX.Post({
                            route: `/create-donwloaded/${id}`,
                            withAuth: true,
                            body: {}
                        });
                        userX.localSongs.push(`ECK ${num}.mp3`);
                        utilsX.setNotif({
                            title: song.title,
                            message: "song.donwloadComplete",
                            type: "success",
                            timeVisible: 5,
                            position: "top-center",
                        });    
                    })
                }
                else{
                    //save pista
                    fetch(files[0])
                            .then(async(response) => {
                                const arrayBuffer = await response.arrayBuffer();
                                await saveFile(arrayBuffer, `ECK ${song.num}.mp3`);
                            })
                            .catch((error) => console.log(error.message));
    
                     if(song.hasVoice){
                            //download voice
    
                            fetch(files[1])
                            .then(async(response) => {
                                const arrayBuffer = await response.arrayBuffer();
                                await saveFile(arrayBuffer, `${song.num}.mp3`);
                            })
                            .catch((error) => console.log(error.message));                        
                    }
                    await requesterX.Post({
                        route: `/create-donwloaded/${id}`,
                        withAuth: true,
                        body: {}
                    });
                    userX.localSongs.push(`ECK ${num}.mp3`);
                    utilsX.setNotif({
                        title: song.title,
                        message: "song.donwloadComplete",
                        type: "success",
                        timeVisible: 5,
                        position: "top-center",
                    });
    
    
                }

            }
            catch(e){
                if(e.response.status === 402){
                    utilsX.setNotif({
                        title: "song.storage",
                        message: "song.maxDownloadReached",
                        type: "error",
                        timeVisible: 10,
                        position: "top-center",
                    });
                }else{
                    utilsX.deffaultError();
                }
                
            }

            
            
        });
    }
    const saveFile = async (data,name) => {
       
        if (!('showDirectoryPicker' in window)) {
            //if OPFS is not available, save the files in the indexedDB
            return saveFileIndexDB(data,name);
        } 
        //else save in OPFS
        const opfsRoot = await navigator.storage.getDirectory();
        const directoryHandle = await opfsRoot
            .getDirectoryHandle(storageDir, {create: true});
        const nestedFileHandle = await directoryHandle
            .getFileHandle(name, {create: true});
        const file = await nestedFileHandle.createWritable()
        await file.write(data);
        await file.close();
    }
    function saveFileIndexDB(data,name){
        const dbName = "eki-binarySongDB";
        const request = indexedDB.open(dbName, 1);

        request.onupgradeneeded = function(event) {
            const db = event.target.result;
            db.createObjectStore("files", { keyPath: "id" });
        };

        request.onsuccess = function(event) {
            const db = event.target.result;
            const transaction = db.transaction(["files"], "readwrite");
            const objectStore = transaction.objectStore("files");
            objectStore.put({ id: name, data });
        };
        
    }

    /*function downloadFile() {
        const dbName = "myDatabase";
        const request = indexedDB.open(dbName, 1);
      
        request.onsuccess = function(event) {
          const db = event.target.result;
          const transaction = db.transaction(["files"], "readonly");
          const objectStore = transaction.objectStore("files");
          const getRequest = objectStore.get("file1");
      
          getRequest.onsuccess = function(event) {
            const fileBlob = event.target.result.data;
            const url = URL.createObjectURL(fileBlob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'retrieved-file.txt';
            document.body.appendChild(a);
            a.click();
            URL.revokeObjectURL(url);
          };
        };
      }*/

    const getLocalFile = async (song) => {

        const {num} = song;
        if (!('showDirectoryPicker' in window)) {
            //if OPFS not available load song from indexDB
                const data = await binarySongX.loadSongData(num);
            return data;
        }
        //else load song from OPFS
        const opfsRoot = await navigator.storage.getDirectory();

        const directoryHandle = await opfsRoot
            .getDirectoryHandle(storageDir, {create: false});

        const nestedFileHandle = await directoryHandle
            .getFileHandle(`ECK ${num}.mp3`, {create: false});
        const file = await nestedFileHandle.getFile();
        let voiceFile;
        if(song.hasVoice){
            const voiceFileHandle = await directoryHandle
                .getFileHandle(`${num}.mp3`, {create: false});
            if(voiceFileHandle){
                voiceFile = await voiceFileHandle.getFile();
            }
        }


       
        return {file, voiceFile};
    }

    const listDirectory = async () => {
        
        const opfsRoot = await navigator.storage.getDirectory();

        const directoryHandle = await opfsRoot
            .getDirectoryHandle(storageDir, {create: true});

       /* const nestedFileHandle = await directoryHandle
            .getFileHandle('audio.mp3', {create: false});*/

        for await (let [name, handle] of directoryHandle.entries()) {
            console.log(name);
        }

        return;

        const file = await nestedFileHandle.getFile();

        //console.log(file);

        const audioContext = new AudioContext();
        const fileReader = new FileReader();

        fileReader.onloadend = async(event) => {

            console.log(event);

            const myArrayBuffer = event.target.result;
            console.log(myArrayBuffer);

            const audioBuffer = await audioContext.decodeAudioData(myArrayBuffer);

            const sourceRecord = audioContext.createBufferSource();

            sourceRecord.connect(audioContext.destination);
            sourceRecord.buffer = audioBuffer;

            sourceRecord.start(0);


        };
        console.log("load blob")

        //Load blob
        fileReader.readAsArrayBuffer(file);

        /* console.log(directoryHandle.entries());
         */
        /*
         console.log("list dire");
         const dir = await navigator.storage.getDirectory();
         */
    }
    const requestPersitentStorage = async () => {
        const supported = 'storage' in navigator;

        // check if persistent storage is supported
        const persistentStorageSupported = navigator.storage && navigator.storage.persist;

        // request persistent storage
        const persist = await navigator.storage.persist();

        // check if app already has persistent storage
        const hasPersistentStorage = await navigator.storage.persisted();

    }
    const usage = async () => {
        // get usage statistics
        const {quota, usage, usageDetails} = await navigator.storage.estimate();
    }
   const getAllDownloadedSongs = async () => {
    
    if (!('showDirectoryPicker' in window)) {
        //if OPFS not available, load list from indexdb
        return binarySongX.allBinarySongs;
    }
       
        try{
            //else load songs from OPFS
            const opfsRoot = await navigator.storage.getDirectory();

            const directoryHandle = await opfsRoot
                .getDirectoryHandle(storageDir, {create: true});
            const songs = [];
            for await (let [name] of directoryHandle.entries()) {
                if(name.endsWith(".mp3")){
                    songs.push(name);
                }
            }
            return songs;
        }
        catch (e){
            console.log("storage not available");
            return [];
        }


    }
    const removeLocalFile = async (song) => {
        const {num, id} = song;

        if ('showDirectoryPicker' in window){
            //remove from OPFS
            const opfsRoot = await navigator.storage.getDirectory();

            const directoryHandle = await opfsRoot
                    .getDirectoryHandle(storageDir, {create: false});
            console.log(directoryHandle);
            //remove pista
            await directoryHandle.removeEntry(`ECK ${num}.mp3`);
            //remove voz guia
            await directoryHandle.removeEntry(`${num}.mp3`);
        }
        else{
            //remove from indexedDB
            removeSongData(num);

        }
        //delete from API
        await requesterX.Get({
            route: `/remove-donwloaded/${id}`,
            withAuth: true
        });
        
    }
    return {downloadSong,getLocalFile,listDirectory, getAllDownloadedSongs, removeLocalFile};
}
