import { defineStore } from "pinia";
import { ref } from "vue";
import { useCookie, } from "vue-cookie-next";
import { useState } from "vue-gtag-next";
import moment from 'moment';

export const useUtilsStore = defineStore("utils", () => {
  const { isEnabled } = useState();
  const cookie = useCookie();

  const crumbDetail = ref("");
  const isMenuOpened = ref(false);
  const errorState = ref(false);
  const showScore = ref(false);
  const currentReaction = ref(null);
  const reactionsCount = ref([]);
  const cookiesAllowed = ref(
    cookie.isCookieAvailable("cookies_consent") &&
      cookie.getCookie("cookies_consent") === "true"
  );
  const showCookieBanner = ref(cookie.getCookie("cookies_consent") != "true");
  const notif = ref({
    title: "title",
    message: "msg",
    type: "info",
    isVisible: false,
    timeVisible: 3,
    position: "top-center",
    showTimeout: null,
    closeTimeout: null,
  });

  if (cookiesAllowed.value) {
    runCookieDependencies();
  }

  function stopCookieDependencies() {
    console.log("stopCookieDependencies");
    cookie.removeCookie("cookies_consent");
    cookiesAllowed.value = true;
    isEnabled.value = false;
  }

  function runCookieDependencies() {
    console.log("runCookieDependencies");
    cookie.setCookie('cookies_consent', true, {
      expire: moment().add(1, 'year').startOf('year').toDate()
    });
    cookiesAllowed.value = true;
    showCookieBanner.value = false;
    isEnabled.value = true;
  }

  function setCrumbDetail(label) {
    crumbDetail.value = label;
  }

  function playReaction(label) {
    let position = reactionsCount.value.findIndex((x) => x.emoji === label);

    if (position !== -1) {
      reactionsCount.value[position].count++;

      currentReaction.value = reactionsCount.value[position];
    } else {
      reactionsCount.value.push({
        emoji: label,
        count: 1,
      });

      currentReaction.value = {
        emoji: label,
        count: 1,
      };
    }
  }

  function cleanReactions() {
    reactionsCount.value = [];
  }

  function showNotif() {
    if (!notif.value.isVisible) {
      notif.value.isVisible = true;
    }
  }

  function closeNotif() {
    if (notif.value.isVisible) {
      notif.value.isVisible = false;
      clearTimeout(notif.value.closeTimeout);
      notif.value.closeTimeout = null;
    }
  }

  function deffaultError() {
    setNotif({
      message: "error.something",
      title: "error.single",
      type: "error",
      timeVisible: 5,
      position: "top-center",
    });
  }

  function loginFirst() {
    setNotif({
      title: "error.warning",
      message: "error.loginFirst",
      type: "warning",
      timeVisible: 5,
      position: "top-center",
    });
  }

  function setNotif({
    title = "title",
    message = "msg",
    type = "info",
    timeVisible = 3,
    position = "top-center",
  }) {
    if (notif.value.isVisible) {
      closeNotif();
    } else {
      clearTimeout(notif.value.showTimeout);
      notif.value.showTimeout = null;
    }

    notif.value = {
      title: title,
      message: message,
      type: type,
      timeVisible: timeVisible,
      position: position,
      showTimeout: setTimeout(showNotif, 100),
      closeTimeout: setTimeout(closeNotif, timeVisible * 1000),
    };
  }

  return {
    errorState,
    crumbDetail,
    notif,
    isMenuOpened,
    showScore,
    setCrumbDetail,
    setNotif,
    closeNotif,
    deffaultError,
    loginFirst,
    playReaction,
    currentReaction,
    reactionsCount,
    cleanReactions,
    showCookieBanner,
    cookiesAllowed,
    runCookieDependencies,
    stopCookieDependencies
  };
});
