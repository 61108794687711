import { defineStore } from "pinia";
import { ref, computed, watch } from "vue";
import { useUtilsStore } from "./utils";
import { useUserStore } from "./user";
import { useRequesterStore } from "./requester";
import { Lrc } from "lrc-kit";
import "../plugins/howler.core";
import {useBackgroundFetch} from "@/composables/useBackgroundFetchApi";
export const usePlayerStore = defineStore("player", () => {
  // ? Recovering stored Player and initializing other StoresX
  const storedPlayer = JSON.parse(localStorage.getItem("ekiPersistedPlayer"));
  const utilsX = useUtilsStore();
  const userX = useUserStore();
  const requesterX = useRequesterStore();
  const { getLocalFile } = useBackgroundFetch();
  //*|===========================================================================|
  //*|                                                                           |
  //*|                         ~~~> EKI PLAYER STATE <~~~                        |
  //*|                                                                           |
  //*|===========================================================================|
  // ? Usefull refs to show/hide & update UX
  const isPlayerReady = ref(false);
  const loadingPlayer = ref(false);
  const canPitchShift = ref(null);
  const loading = ref(false);
  const playing = ref(false);
  const showTitle = ref(false);
  const screenMode = ref(1);

  // ? Pitch Shift controllers
  const semitone = ref(1);
  const shifter = ref(null);
  const pitchControl = ref(0);

  // ? For the queue and history states
  const queue = ref(storedPlayer?.queue ?? []);
  const reproducedQueue = ref([]);

  // ? Effects state Info
  const effectList = ref([]);
  const playingEffects = ref([]);
  const currentEffectIndex = ref(-1);
  const effectsVolume = ref(storedPlayer?.effectsVolume ?? 0.5);

  // ? Show Score utils
  const showScore = ref(false);
  const songRate = ref(75);

  // ? Current Song Info
  const currentSong = ref(null);
  const volume = ref(storedPlayer?.volume ?? 1);
  const lyrics = ref(null);
  const lightContent = ref(null);

  // ? Voice Song Utils
  const isSongVoiceActive = ref(false);

  // ? Current Song Time Info
  const progressRAF = ref(null);
  const songSeconds = ref(null);
  const songProgress = ref(0);

  const songDuration = computed(() => {
    if (songSeconds.value != null) {
      const min = Math.floor(songSeconds.value / 60) || 0;
      const secs = Math.floor(songSeconds.value - min * 60) || 0;
      return `${min}${secs > 10 ? ":0" : ":"}${secs}`;
    }
    return null;
  });
  const progressInSeconds = computed(() => {
    if (songSeconds.value != null) {
      return songSeconds.value * (songProgress.value / 100);
    }

    return 0;
  });

  // ? Next Song Info
  const nextSong = computed(() => {
    if (queue.value.length > 0) {
      return { ...queue.value[0] };
    }
    return null;
  });

  // ? Remote Control State Info
  const controlState = computed(() => {
    console.log("newControlState");
    return {
      queue: queue.value,
      currentSong: currentSong.value
        ? {
            id: currentSong.value.id,
            title: currentSong.value.title,
            num: currentSong.value.num,
            art: currentSong.value.art,
            gen: currentSong.value.gen,
          }
        : null,
      volume: volume.value,
      loading: loading.value,
      playing: playing.value,
      hasPreviousSongs: reproducedQueue.value.length > 0,
    };
  });

  // ? Lyrics State Info
  const mainLrc = ref(null);
  const lrcDisplayedIndex = ref(-1);
  const currentLyrics = ref(null);
  let lrcTimeRange = null;

  // ? Usefull const
  const songsUrl = `${import.meta.env.VITE_PLAYER_URL}/song-play`;
  const voiceUrl = `${import.meta.env.VITE_PLAYER_URL}/song-voice`;
  const lyricsUrl = `${import.meta.env.VITE_PLAYER_URL}/lyrics`;
  const selectLineRegex = /^\s*(\d+)\s*:\s*(\d+(\s*[\.:]\s*\d+)?)\s*$/; //* match `[512:34.1] lyric content`
  const selectWordRegex = /(<|\[)+(\d*:\d*.\d*)+(>|\])/g; //* match `<00:32.36>`

  //*|===========================================================================|
  //*|                                                                           |
  //*|                  ~~~> EKI PLAYER FUNCTIONS & ACTIONS <~~~                 |
  //*|                                                                           |
  //*|===========================================================================|
  async function initPitcher() {
    if (Howler.ctx?.audioWorklet && userX.isPitchActive) {
      canPitchShift.value = true;

      await Howler.ctx.audioWorklet.addModule("/worklets/phase-vocoder.js");

      shifter.value = new AudioWorkletNode(
        Howler.ctx,
        "phase-vocoder-processor"
      );

      watch(
        () => semitone.value,
        (newSemitone) => {
          if(shifter.value) {
            shifter.value.parameters.get("pitchFactor").value = newSemitone * 1;
          }
        }
      );

      return true;
    }
    
    return false;
  }
  async function connectPitch() {
    try {
      if (canPitchShift.value === null) {
        await initPitcher();
      }

      if (canPitchShift.value && currentSong.value?.sound && shifter.value) {
        const source = Howler.ctx.createMediaElementSource(
          currentSong.value?.sound._sounds[0]._node
        );
        source.connect(shifter.value);
        shifter.value.connect(Howler.masterGain);
        Howler.masterGain.connect(Howler.ctx.destination);
      }
    } catch (error) {
      console.log(error);
    }
  }
  function playKeepingQueue(song) {
    console.log("playKeepingQueue", song);
    if (currentSong.value?.id === song.id) {
      utilsX.setNotif({
        title: "error.warning",
        message: "error.songInProgress",
        type: "warning",
        timeVisible: 5,
        position: "top-center",
      });
    } else if (!loading.value) {
      if (currentSong.value) {
        stop();
      }
      queue.value.unshift({ ...song });
      play();
    }
  }
  async function pausePlayVoice() {
    console.log("pausePlayVoice");
    if (
      currentSong.value &&
      currentSong.value.hasVoice &&
      currentSong.value.voice &&
      currentSong.value.sound
    ) {
      currentSong.value.voice?.seek(currentSong.value.sound.seek() || 0);

      if (isSongVoiceActive.value) {
        if (currentSong.value.isOnlyVoice) {
          // * fade down only voice  to 0
          currentSong.value.voice.fade(volume.value, 0, 1000);
        } else {
          // * fade down song voice to 0 and fade up on sound to volume
          currentSong.value.voice.fade(volume.value, 0, 1000);
          currentSong.value.sound.fade(0, volume.value, 1000);
        }
      } else {
        if (currentSong.value.isOnlyVoice) {
          // * fade up only voice to volume
          currentSong.value.voice.fade(0, volume.value, 1000);
        } else {
          // * fade up only voice to volume and fade down sound
          currentSong.value.voice.fade(0, volume.value, 1000);
          currentSong.value.sound.fade(volume.value, 0, 1000);
        }
      }

      isSongVoiceActive.value = !isSongVoiceActive.value;
    }
  }
  async function pausePlay() {
    console.log("pausePlay");
    if (currentSong.value) {
      if (playing.value) {
        currentSong.value.sound?.pause();
        currentSong.value.voice?.pause();
      } else {
        currentSong.value.sound?.play();
        currentSong.value.voice?.play();
      }

      playing.value = !playing.value;
    } else if (queue.value.length > 0) {
      play();
    } else {
      utilsX.setNotif({
        title: "error.warning",
        message: "error.noSongs",
        type: "warning",
        timeVisible: 5,
        position: "top-center",
      });
    }
  }
  async function play() {
    console.log("play");
    try {
      if (userX.isSubscribed) {
        if (loading.value) {
          return;
        }
        if (queue.value.length > 0) {
          loading.value = true;

          const songData = { ...queue.value.shift() };

          const {num} = songData;
          
          let songUrl, songVoiceUrl;
          
          const songDetail = await requesterX.Get({
            route: `/song-detail/${songData.id}`,
            withAuth: true,
            localAvailable: userX.localSongs.includes(`ECK ${num}.mp3`)
          });

          const { jwt, songHistoryId, song } = songDetail.data;

          if(!userX.localSongs.includes(`ECK ${num}.mp3`)){
            songUrl = `${songsUrl}/ECK%20${num}.mp3?auth=${jwt}`;
            songVoiceUrl =`${voiceUrl}/${num}.mp3?auth=${jwt}`;

          }
          else{
            const {file, voiceFile} = await getLocalFile(song);
            songUrl = await blobToData(file);
            if(voiceFile){
              songVoiceUrl =  await blobToData(voiceFile);
            }
          }
         

          lyrics.value = song.lyricsText;

          songData.lyric = lyrics.value;
          songData.songHistoryId = songHistoryId;
          songData.jwt = jwt;
          songData.songNum = num;
          songData.hasVoice = song.hasVoice || song.isOnlyVoice;
          songData.isOnlyVoice = song.isOnlyVoice;
          songData.idVideo = song.videoFile;

          if (!songData.hasVoice && isSongVoiceActive.value) {
            isSongVoiceActive.value = false;
          }

          if ("mediaSession" in navigator) {
            navigator.mediaSession.metadata = new MediaMetadata({
              title: songData.title,
              artist: songData.art.name,
              album: `Ecuakaraoke | ${songData.gen.name}`,
              artwork: [{ src: songData.art.img ?? songData.gen.img }],
            });

            navigator.mediaSession.setActionHandler("play", () => pausePlay());
            navigator.mediaSession.setActionHandler("pause", () => pausePlay());
          }

          songData.sound = new Howl({
            src: [songUrl],
            html5: true,
            volume:
              isSongVoiceActive.value && !songData.isOnlyVoice
                ? 0
                : volume.value,
            format: ["mp3"],

            onplay: async function () {
              playing.value = true;
              songSeconds.value = songData.sound.duration();
              // refreshTimes.value = !refreshTimes.value;
              progressRAF.value = requestAnimationFrame(whilePlaying);
            },
            onplayerror: async function (id, error) {
              console.log("Howl.onPlayError()", id, error);
            },
            onload: async function () {
              connectPitch();

              showTitle.value = true;
              setTimeout(() => {
                showTitle.value = false;
              }, 3000);

              if (songData.hasVoice) {
                console.log("cargar voz guia");
                songData.voice = new Howl({
                  src: [songVoiceUrl],
                  html5: true,
                  volume: isSongVoiceActive.value ? volume.value : 0,
                  onload: async function () {
                    currentSong.value?.sound?.play();
                    currentSong.value?.voice?.play();
                  },
                  onloaderror: async function (id, error) {
                    console.log("error al cargar voz guía");
                    if (isSongVoiceActive.value) {
                      currentSong.value?.sound?.volume(volume.value);
                      isSongVoiceActive.value = false;
                    }

                    currentSong.value.hasVoice = false;
                    currentSong.value?.voice?.stop();
                    delete currentSong.value?.voice;
                    currentSong.value?.sound?.play();
                  },
                });
              } else {
                currentSong.value?.sound.play();
              }
            },
            onloaderror: async function (id, error) {
              console.log("Howl.onLoadError()", id, error);
              loading.value = false;
              stop();

              utilsX.errorState = true;
            },
            onseek: function (id) {
              console.log("Howl.onseek()", id);
            },
            onend: async function (id) {
              semitone.value = 1;
              console.log("Howl.onend()", id);
              //and the conteo effect takes like 3 seconds to finish
             
             /* const resp = await requesterX.Post({
                route: "/finish-song",
                withAuth: true,
                body: {
                  historyId: songData.songHistoryId,
                },
              });

              const { score } = resp.data;*/

              setTimeout(()=>{
                //min: 70; max: 100
                songRate.value = Math.floor(Math.random() * (100 - 70 + 1) + 70);//score;
                requesterX.Post({
                  route: "/finish-song",
                  withAuth: true,
                  body: {
                    songId: songData.id,
                    historyId: songData.songHistoryId,
                    rate: songRate.value
                  },
                });
              },1000);

              

              if (!userX.settings.functionallity.showCalification) {
                await skip("next");
              } else {
                utilsX.showScore = true;
              }
            },
            onpause: async function (id) {},
            onstop: async function (id) {
              //stop and destroy current voice
            },
          });

          currentSong.value = songData;
        } else {
          utilsX.setNotif({
            title: "error.warning",
            message: "error.noMoreSongs",
            type: "warning",
            timeVisible: 5,
            position: "top-center",
          });
        }
      } else {
        utilsX.setNotif({
          title: "error.warning",
          message: "error.noSubscription",
          type: "warning",
          timeVisible: 5,
          position: "top-center",
        });
      }
    } catch (error) {
      console.log({ error });
      currentSong.value = null;
      if (error?.response?.status === 402) {
        userX.updateUserIsSubscribed(false);
        utilsX.setNotif({
          title: "error.warning",
          message: error.response.data.data,
          type: "warning",
          timeVisible: 5,
          position: "top-center",
        });
      } else {
        utilsX.deffaultError();
      }
    } finally {
      loading.value = false;
    }
  }
  function stop(restart = false) {
    console.log("stop: restart?", restart);
    if (currentSong.value) {
      currentSong.value.sound?.stop();
      currentSong.value.voice?.stop();
      delete currentSong.value.voice;
      delete currentSong.value.sound;
      delete currentSong.value.lyric;
      lyrics.value = null;
      restart
        ? queue.value.unshift({ ...currentSong.value })
        : reproducedQueue.value.push({ ...currentSong.value });
      currentSong.value = null;
      songSeconds.value = null;
      songProgress.value = 0;
      lightContent.value = null;
      lrcDisplayedIndex.value = -1;
      currentLyrics.value = null;
      playing.value = false;
      showTitle.value = false;
    }
  }
  async function skip(direction) {
    console.log("skip direc: ", direction);
    switch (direction) {
      case "prev":
        if (progressInSeconds.value <= 3) {
          if (reproducedQueue.value.length > 0) {
            stop();
            queue.value.unshift(...reproducedQueue.value.splice(-2, 2));
            play();
          } else {
            stop(true);
          }
        } else if (currentSong.value) {
          seek(0);
        } else if (reproducedQueue.value.length > 0) {
          queue.value.unshift(reproducedQueue.value.pop());
          play();
        }
        break;
      case "next":
        if (currentSong.value) {
          stop();
        }
        if (queue.value.length > 0) {
          play();
        }
        break;
    }
  }
  function skipTo(queueIndex) {
    console.log("skipTo: ", queueIndex);
    if (queue.value[queueIndex] != undefined) {
      if (currentSong.value) {
        stop();
      }
      if (queueIndex === 0) {
        play();
      } else {
        reproducedQueue.value.push(...queue.value.splice(0, queueIndex));
        play();
      }
    }
  }
  function changeVolume(vol) {
    if (vol != volume.value) {
      volume.value = vol;
      changeEffectsVolume((vol * 1) / 2);
      if (
        currentSong.value?.sound &&
        (!isSongVoiceActive.value || currentSong.value?.isOnlyVoice)
      ) {
        currentSong.value.sound.volume(vol);
      }
      if (currentSong.value?.voice && isSongVoiceActive.value) {
        currentSong.value.voice.volume(vol);
      }
    }
  }
  function whilePlaying() {
    let seek = currentSong.value?.sound.seek() || 0;
    let percent = (seek / songSeconds.value) * 100 || 0;
    songProgress.value = percent;
    if (showTitle.value && percent >= 2) {
      showTitle.value = false;
    }
    progressRAF.value = requestAnimationFrame(whilePlaying);
  }
  function seek(percent) {
    try {
      if (lrcTimeRange && mainLrc.value && playing.value && currentSong.value) {
        console.log("seek on ", {
          percent,
          secs: songSeconds.value,
          seekIn: (songSeconds.value * percent) / 100,
        });

        const timePosition = (songSeconds.value * percent) / 100;
        console.log("Buscar tiempo: ", timePosition);
        currentSong.value.sound?.seek(timePosition);
        currentSong.value.voice?.seek(timePosition);

        if (timePosition < lrcTimeRange[0]) {
          lrcDisplayedIndex.value = -1;
          currentLyrics.value = null;
        } else if (timePosition > lrcTimeRange[1]) {
          lrcDisplayedIndex.value = mainLrc.value.length - 1;
          currentLyrics.value = null;
        } else {
          const lrcLineindex = mainLrc.value.findIndex(
            (line) => line.timestamp >= timePosition
          );
          lrcDisplayedIndex.value = lrcLineindex;
          if (lrcLineindex != -1) {
            currentLyrics.value = mainLrc.value[lrcLineindex].content.current;
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
  watch(
    [queue.value, () => volume.value, () => effectsVolume.value],
    ([newQueue, newVol, newEffectsVol]) => {
      savePlayerState(newQueue, newVol, newEffectsVol);
    }
  );
  function savePlayerState(newQueue, newVol, newEffectsVol) {
    localStorage.setItem(
      "ekiPersistedPlayer",
      JSON.stringify({
        volume: newVol,
        effectsVolume: newEffectsVol,
        queue: newQueue.map((song) => {
          const toSaveSong = { ...song };
          delete toSaveSong.lyric;
          delete toSaveSong.sound;
          delete toSaveSong.voice;
          return toSaveSong;
        }),
      })
    );
  }

  //*|===========================================================================|
  //*|                                                                           |
  //*|                 ~~~> SOUND EFECTS FUNCTIONS & ACTIONS <~~~                |
  //*|                                                                           |
  //*|===========================================================================|

  function setUpEffects(effects) {
    effectList.value = effects.map((effect) => {
      return {
        name: effect.name,
        src: `/${effect.url}`,
      };
    });
  }
  function findAndPlayEffect(id) {
    playEffect(effectList.value.findIndex((el) => el.name === id));
  }
  async function playEffect(pos) {
    console.log("playingEffect");
    
    if (pos === currentEffectIndex.value) {
      stopEffect();
    } else if (pos >= 0 && pos < effectList.value.length) {
      if (currentEffectIndex.value != -1 && playingEffects.value.length > 0) {
        effectList.value[currentEffectIndex.value].sound.fade(
          effectList.value[currentEffectIndex.value].sound.volume(),
          0,
          1000
        );
      }

      if (effectList.value[pos].sound) {
        effectList.value[pos].sound.volume(effectsVolume.value);
        effectList.value[pos].sound.play();
        currentEffectIndex.value = pos;
      } else {
        console.log(effectList.value[pos].src);
        effectList.value[pos].sound = new Howl({
          src: [effectList.value[pos].src],
          html5: true,
          volume: effectsVolume.value,
          format: ["mp3"],
          onplay: function (id) {
            if (!playingEffects.value.includes(id)) {
              playingEffects.value.push(id);
            }
          },
          onend: async function (id) {
            if (playingEffects.value.includes(id)) {
              var index = playingEffects.value.indexOf(id);
              playingEffects.value.splice(index, 1);
            }
            currentEffectIndex.value = -1;
          },
          onstop: function (id) {
            if (playingEffects.value.includes(id)) {
              var index = playingEffects.value.indexOf(id);
              playingEffects.value.splice(index, 1);
            }
            currentEffectIndex.value = -1;
          },
        });

        effectList.value[pos].sound.play();

        currentEffectIndex.value = pos;
      }
    }
  }
  function stopEffect() {
    if (
      currentEffectIndex.value >= 0 &&
      currentEffectIndex.value < effectList.value.length
    ) {
      effectList.value[currentEffectIndex.value].sound.fade(
        effectList.value[currentEffectIndex.value].sound.volume(),
        0,
        500
      );
      setTimeout(
        () => effectList.value[currentEffectIndex.value]?.sound.stop(),
        500
      );
    }
  }
  function changeEffectsVolume(vol) {
    if (vol != effectsVolume.value) {
      effectsVolume.value = vol;
      if (
        currentEffectIndex.value != -1 &&
        effectList.value[currentEffectIndex.value].sound
      ) {
        effectList.value[currentEffectIndex.value].sound.volume(vol);
      }
    }
  }

  //*|===========================================================================|
  //*|                                                                           |
  //*|                    ~~~> QUEUE FUNCTIONS & ACTIONS <~~~                    |
  //*|                                                                           |
  //*|===========================================================================|

  function addToQueue(song, { play = false } = {}) {
    if (play) {
      if (currentSong.value) {
        stop();
      }
      queue.value = [song];
      play();
    } else {
      queue.value.push(song);
    }
  }
  function addArrayToQueue(songs) {
    queue.value.push(...songs);
  }
  function removeFromQueue(index) {
    queue.value.splice(index, 1);
  }
  function moveSong(index, direction) {
    let toMove = queue.value[index];

    queue.value[index] = queue.value[index + direction];
    queue.value[index + direction] = toMove;
  }
  function updateFromQueue(index, song) {
    queue.value[index] = song;
  }
  function cleanQueue() {
    queue.value.splice(0, queue.value.length);
  }

  //*|===========================================================================|
  //*|                                                                           |
  //*|                  ~~~> LRC PLAYER FUNCTIONS & ACTIONS <~~~                 |
  //*|                                                                           |
  //*|===========================================================================|

  watch(
    () => lyrics.value,
    (newLrc) => {
      cleanLrcRunnerData();
      if (newLrc) {
        prepareLrcKaraokeRunner(newLrc);
      }
    }
  );
  watch(
    () => progressInSeconds.value,
    (newTime) => {
      const nextIndex = lrcDisplayedIndex.value + 1;
      if (mainLrc.value && nextIndex >= 0 && nextIndex < mainLrc.value.length) {
        const currentLine = mainLrc.value[nextIndex];

        if (nextIndex === mainLrc.value.length - 1) {
          setTimeout(() => {
            currentLyrics.value.classic = "";
          }, 1500);
        }

        if (newTime >= currentLine.timestamp) {
          currentLyrics.value = currentLine.content.current;
          lrcDisplayedIndex.value += 1;

          if (lightContent.value != currentLine.showLight) {
            setLightContent(currentLine.showLight);
          }
        }
      }
    }
  );
  function prepareLrcKaraokeRunner(lyrics) {
    try {
      console.log(lyrics);
      const songLrc = Lrc.parse(lyrics).lyrics;
      console.log({ lyrics, songLrc });
      const firstTime = songLrc[0].timestamp - 3.2;
      const finalLrc = [];
      const size = songLrc.length;

      for (let index = 0; index < songLrc.length; index++) {
        if (index === 0) {
          const lineContent = songLrc[index].content.replace(
            selectWordRegex,
            ""
          );
          const nextLineContent = songLrc[index + 1].content.replace(
            selectWordRegex,
            ""
          );

          const whileLightsContent = {
            current: {
              classic: `<div class="lrcline">${lineContent}</div><div class="lrcline">${nextLineContent}</div>`,
              fullLyrics: `${calcPrevLrcLines(lyrics, -1, size)}<div class="lrcline"></div>${calcNextLrcLines(songLrc, -1, size)}`,
            },
          };

          finalLrc.push(
            ...[
              {
                showLight: "3",
                timestamp: firstTime.toFixed(2) * 1,
                content: whileLightsContent,
              },
              {
                showLight: "2",
                timestamp: (firstTime + 1).toFixed(2) * 1,
                content: whileLightsContent,
              },
              {
                showLight: "1",
                timestamp: (firstTime + 2).toFixed(2) * 1,
                content: whileLightsContent,
              },
              {
                showLight: "0",
                timestamp: (firstTime + 3).toFixed(2) * 1,
                content: whileLightsContent,
              },
            ]
          );
        }

        finalLrc.push(...calcPaintInLine(songLrc, index, size));
      }

      lrcTimeRange = [
        finalLrc[0].timestamp,
        finalLrc[finalLrc.length - 1].timestamp,
      ];

      mainLrc.value = finalLrc;
    } catch (error) {
      console.log(error);
    }
  }
  function calcPaintInLine(lyrics, pos, size) {
    const line = lyrics[pos];
    const nextLine = pos + 1 < size ? lyrics[pos + 1] : "";
    const prevLine = pos - 1 >= 0 ? lyrics[pos - 1] : "";
    const lines = [];
    const syllables = lineTimeParser(line.content);
    const lineContent = line.content.replace(selectWordRegex, "");
    const prevLineContent =
      prevLine != "" ? prevLine.content.replace(selectWordRegex, "") : "";
    const nextLineContent =
      nextLine != "" ? nextLine.content.replace(selectWordRegex, "") : "";
    const syllablesSize = syllables.length;

    let toPaint = "";

    for (let index = 0; index < syllablesSize; index++) {
      const syllable = syllables[index];
      toPaint += syllable.content;

      lines.push({
        showLight: null,
        timestamp: syllable.timestamp.toFixed(2) * 1,
        content: {
          current: {
            classic:
              pos % 2
                ? pos + 1 === size
                  ? `<div class="lrcline"><span class="active-lrc">${prevLineContent}</span></div><div class="lrcline">${lineContent.replace(toPaint, `<span class="active-lrc">${toPaint}</span>`)}</div>`
                  : `<div class="lrcline">${nextLineContent}</div><div class="lrcline">${lineContent.replace(toPaint, `<span class="active-lrc">${toPaint}</span>`)}</div>`
                : `<div class="lrcline">${lineContent.replace(toPaint, `<span class="active-lrc">${toPaint}</span>`)}</div><div class="lrcline">${nextLineContent}</div>`,
            fullLyrics: `${calcPrevLrcLines(lyrics, pos, size)}<div class="lrcline">${lineContent.replace(toPaint, `<span class="active-lrc">${toPaint}</span>`)}</div>${calcNextLrcLines(lyrics, pos, size)}`,
          },
        },
      });
    }

    if (
      nextLine &&
      nextLine.timestamp - syllables[syllablesSize - 1].timestamp >= 5
    ) {
      const whileLightsContent = {
        current: {
          classic:
            pos % 2
              ? pos + 1 === size
                ? `<div class="lrcline"><span class="active-lrc">${prevLineContent}</span></div><div class="lrcline">${lineContent.replace(toPaint, `<span class="active-lrc">${toPaint}</span>`)}</div>`
                : `<div class="lrcline">${nextLineContent}</div><div class="lrcline">${lineContent.replace(toPaint, `<span class="active-lrc">${toPaint}</span>`)}</div>`
              : `<div class="lrcline">${lineContent.replace(toPaint, `<span class="active-lrc">${toPaint}</span>`)}</div><div class="lrcline">${nextLineContent}</div>`,
          fullLyrics: `${calcPrevLrcLines(lyrics, pos, size)}<div class="lrcline">${lineContent.replace(toPaint, `<span class="active-lrc">${toPaint}</span>`)}</div>${calcNextLrcLines(lyrics, pos, size)}`,
        },
      };

      lines.push(
        ...[
          {
            showLight: null,
            timestamp:
              (syllables[syllablesSize - 1].timestamp + 0.5).toFixed(2) * 1,
            content: {
              ...whileLightsContent,
              current: {
                classic: ``,
                fullLyrics: `${calcPrevLrcLines(lyrics, pos, size)}<div class="lrcline">${lineContent.replace(toPaint, `<span class="active-lrc">${toPaint}</span>`)}</div>${calcNextLrcLines(lyrics, pos, size)}`,
              },
            },
          },
          {
            showLight: "3",
            timestamp: (nextLine.timestamp - 3.2).toFixed(2) * 1,
            content: whileLightsContent,
          },
          {
            showLight: "2",
            timestamp: (nextLine.timestamp - 2.2).toFixed(2) * 1,
            content: whileLightsContent,
          },
          {
            showLight: "1",
            timestamp: (nextLine.timestamp - 1.2).toFixed(2) * 1,
            content: whileLightsContent,
          },
          {
            showLight: "0",
            timestamp: (nextLine.timestamp - 0.2).toFixed(2) * 1,
            content: whileLightsContent,
          },
        ]
      );
    }

    return lines;
  }
  function calcNextLrcLines(lyrics, pos, size) {
    let next = "";

    for (let index = 1; index <= 4; index++) {
      let itemIndex = pos + index;

      if (itemIndex >= 0 && itemIndex < size) {
        next += `<div class="lrcline">${lyrics[itemIndex].content.replace(selectWordRegex, "")}</div>`;
      } else {
        next += `<div class="lrcline"></div>`;
      }
    }

    return next;
  }
  function calcPrevLrcLines(lyrics, pos, size) {
    let prev = "";

    for (let index = 4; index > 0; index--) {
      let itemIndex = pos - index;

      if (itemIndex >= 0) {
        prev += `<div class="lrcline"><span class="active-lrc">${lyrics[itemIndex].content.replace(selectWordRegex, "")}</span></div>`;
      } else {
        prev += `<div class="lrcline"></div>`;
      }
    }

    return prev;
  }
  function cleanLrcRunnerData() {
    mainLrc.value = null;
    lrcDisplayedIndex.value = -1;
    currentLyrics.value = null;
    lrcTimeRange = null;
  }
  function lineTimeParser(line) {
    const lyrics = [];
    let content = "";

    line
      .trim()
      .split(/<|>/g)
      .forEach(function (line, index) {
        if (index % 2) {
          //IMPAR
          lyrics.push({
            timestamp: parseTime(line),
            content: content,
          });
        } else {
          //PAR
          content = line;
        }
      });

    var lrc = new Lrc();
    lrc.lyrics = lyrics;

    return lrc.lyrics;
  }
  function parseTime(tag) {
    const matches = selectLineRegex.exec(tag);
    const minutes = parseFloat(matches[1]);
    const seconds = parseFloat(
      matches[2].replace(/\s+/g, "").replace(":", ".")
    );
    return minutes * 60 + seconds;
  }

  // TODO Move To Search Bar Component not needed on store
  const suggestList = ref({});
  const searchQuery = ref("");

  function setLightContent(content) {
    lightContent.value = content;
  }
  const blobToData = (blob) => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onloadend = () => {
        const dataurl = reader.result;
        dataurl.startsWith("data:audio")?resolve(dataurl):resolve(`data:audio/mpeg;base64,${dataurl.substr(dataurl.indexOf(',')+1)}`)
      }
      reader.readAsDataURL(blob)
    })
  }

  return {
    // * Usefull refs to show/hide & update UX
    isSongVoiceActive,
    isPlayerReady,
    loadingPlayer,
    canPitchShift,
    loading,
    playing,
    showTitle,
    screenMode,

    // * Pitch Shift controllers
    semitone,

    // * For the queue and history states
    queue,
    reproducedQueue,

    // * Effects state Info
    effectList,
    playingEffects,
    currentEffectIndex,
    effectsVolume,

    // * Show Score utils
    showScore,
    songRate,

    // * Current Song Time Info
    songSeconds,
    songProgress,
    songDuration,

    // * Lyrics Player Info
    currentLyrics,

    // * Next Song Info
    nextSong,

    // * Remote Control State Info
    controlState,

    // * Eki Player Functions & Actions Exports
    playKeepingQueue,
    pausePlay,
    stop,
    skip,
    skipTo,
    changeVolume,
    pausePlayVoice,

    // * Sound Effect Functions & Actions Exports
    setUpEffects,
    findAndPlayEffect,
    playEffect,
    stopEffect,
    changeEffectsVolume,

    // * Queue Functions & Actions Exports
    addToQueue,
    addArrayToQueue,
    removeFromQueue,
    moveSong,
    updateFromQueue,

    lyrics,
    volume,
    currentSong,
    lightContent,
    pitchControl,
    suggestList,
    searchQuery,

    //functions
    seek,
    play,
    setLightContent,
    cleanQueue,
  };
});
