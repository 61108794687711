import { defineStore } from "pinia";
import { ref } from "vue";
import { useUtilsStore } from "@/stores/utils";
export const useDatabaseStore = defineStore("database", () => {
    if(!localStorage.getItem("ekiAppUser")) return;
    const db = ref(null);
    const needsUpgrade = ref(false);
    if (navigator.onLine){
        //check if there is any update
        fetch(`${import.meta.env.VITE_API_URL}/offline/get-db-version`, {
            method: "GET",
        }).then(async(historyVersion)=>{
           
            const {version} = JSON.parse(await historyVersion.text());
            dbEvents(window.indexedDB.open("ekiDB", version));
        }).catch(()=>{
            dbEvents(window.indexedDB.open("ekiDB"));
        });
    }else{
        dbEvents(window.indexedDB.open("ekiDB"));        
    }


    async function dbEvents(indexDB){
        indexDB.onupgradeneeded = async function(event) {
            const db = event.target.result;
            
            const objectStores = event.target.transaction.objectStoreNames;
            if(!objectStores.contains("songs")){
                await createIndex(db,"songs");
            }
            if(!objectStores.contains("artistas")){
                await createIndex(db,"artistas");
            }
            if(!objectStores.contains("generos")){
                await createIndex(db,"generos");
            }
            needsUpgrade.value = true;
        }
        indexDB.onsuccess = async function (event) {
            db.value = event.target.result;
    //        var version =  parseInt(db.version);
           
            
            if(!needsUpgrade.value){
                return;
            }
           
            const utilsX = useUtilsStore();
            let nextResponse = false;
                let lastByte = 0;
                let textFile = "";

                utilsX.setNotif({
                    title: "syncing",
                    message: "syncingDatabaseMessage",
                    type: "warning",
                    timeVisible: 180,
                    position: "top-center",
                });   
               
                do {
                    // Get this chunk
                    const response = await fetch(`${import.meta.env.VITE_API_URL}/offline/get-db`, {
                        method: "GET",
                        headers: {
                            "Range": `bytes=${lastByte}-`,
                           // Authorization: "Bearer " + userX.tokens.token,
                        }
                    });
    
                    // Read this chunk as text and append it to the JSON
                    textFile += await response.text();
                    lastByte = Number(response.headers.get('Content-End'))+1;
                    nextResponse=lastByte===Number(response.headers.get('Content-Size'));//  false
                } while (!nextResponse);
                const {songs,artistas,generos} = JSON.parse(textFile);
                
                await createIndexedDB(db.value,"songs",songs);
                await createIndexedDB(db.value,"generos",generos);
                await createIndexedDB(db.value,"artistas",artistas);

                utilsX.setNotif({
                    title: "finished",
                    message: "finishedSyncMessage",
                    type: "success",
                    timeVisible: 5,
                    position: "top-center",
                });   
        };
    }
    
        
        async function createIndexedDB(db, name, objects){
            const transaction = db.transaction(name, "readwrite");
            const objectStore = transaction.objectStore(name);
    
            for(const i in objects){
                const object = objects[i];
                await objectStore.put(object);
            }
            transaction.oncomplete = function() {
                console.log("All done! for "+name);
            };
        }
        function createIndex(db,name){
            const objectStore = db.createObjectStore(name, { keyPath: "id" });
            objectStore.createIndex("id", "id", { unique: true });
        }
        function getStore(name){
            const transaction = db.value.transaction(name, "readwrite");
            const artistaStore = transaction.objectStore(name);
            return artistaStore;
        }
    
    return { db, getStore };
});