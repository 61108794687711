import { defineStore } from "pinia";
import axios from "axios";
import axiosRetry from "axios-retry";
import AES from "crypto-js/aes";
import encUtf8 from "crypto-js/enc-utf8";
import { useSocketStore } from "./socket";
import { useUserStore } from "./user";
import { useSongsStore } from "./songs";
import { useGenerosStore } from "./generos";
import { useArtistasStore } from "./artistas";
import { useAllSongsStore } from "./allSongs";
import { useHistoryStore } from "./history";
import { usePlaylistStore } from "./playlists";
export const useRequesterStore = defineStore("requester", () => {
  const userX = useUserStore();
  const socketX = useSocketStore();
  const songsX = useSongsStore();
  const generosX = useGenerosStore();
  const artistasX = useArtistasStore();
  const allSongsX = useAllSongsStore();
  const historyX = useHistoryStore();
  const playlistX = usePlaylistStore();

  const cachedUrls = ["/public/get-autocomplete"];
  const deffaultHeader = {
    "Content-Type": "application/json",
  };

  const client = axios.create({
    baseURL: import.meta.env.VITE_API_URL,
    timeout: 30000,
    transformRequest: [
      function (data, headers) {
        if (data) {
          if (!headers.isPublic) {
            const key = userX.cryptoKey;
            const crypted = AES.encrypt(JSON.stringify(data), key).toString();
            const dataToSend = { data: crypted };
            return JSON.stringify(dataToSend);
          } else {
            const crypted = AES.encrypt(
              JSON.stringify(data),
              import.meta.env.VITE_CRIPTO_KEY
            ).toString();
            const dataToSend = { data: crypted };
            return JSON.stringify(dataToSend);
          }
        } else {
          return data;
        }
      },
    ],
    transformResponse: [
      function (data, headers) {
        try {
          if (!data) {
            return { error: "emptyData", data };
          }
          let dataObj;
          if (!headers.isPublic && !headers.ispublic) {
            const key = userX.cryptoKey;
            dataObj = JSON.parse(AES.decrypt(data, key).toString(encUtf8));
          } else {
            dataObj = JSON.parse(
              AES.decrypt(data, import.meta.env.VITE_CRIPTO_KEY).toString(
                encUtf8
              )
            );
          }
          return dataObj ?? data;
        } catch (error) {
          return { error: "decryptError", data };
        }
      },
    ],
  });


  client.interceptors.request.use((config) => {
    console.log("request interceptor");
    console.log(config.url);
    return config;
  });


  client.interceptors.response.use(
    (response) => {
      console.log("response interceptor");
      const {url} = response.config;
      caches.open(url).then((cache) => {
      });
      return response;
    },
    async (error) => {
      console.log("entro error");
      const origitalRequest = error.config;
      if (error.response) {
        if (error.response.status === 403 && !origitalRequest._retry) {
          try {
            origitalRequest._retry = true;
            console.log("original requestttt issssss");
            console.log(origitalRequest);
            const body = {
              token: userX.tokens.token,
              refreshToken: userX.tokens.refreshToken,
            };

            console.log("sendToRefresh: ", body);

            try {
              const freshTokens = await Post({
                route: "/refresh-token",
                body,
                addHeader: {
                  isPublic: true,
                  "Access-Control-Expose-Headers": "isPublic",
                },
              });

              console.log("apires: ", freshTokens);

              userX.setTokens(freshTokens.data);
            } catch (error) {
              console.log(error);
              if (error.response.data.error === "decryptError") {
                const decrypted = JSON.parse(
                  AES.decrypt(
                    error.response.data.data,
                    import.meta.env.VITE_CRIPTO_KEY
                  ).toString(encUtf8)
                );
                console.log(decrypted);
                userX.setTokens(decrypted);
              }
            }

            setTimeout(() => {
              return client(origitalRequest);  
            }, 1000);
            
          } catch (_error) {
            return Promise.reject(_error);
          }
        } else if (error.response.status === 401) {
          await Get({
            route: "/logout",
            withAuth: true,
          });
          const { useFirebaseStore } = await import("./firebase");
          const firebaseX = useFirebaseStore();
          await firebaseX.logout();
          userX.setApiUser(null);
          socketX.controlRoom = null;
          userX.cleanConfig();
        }
      }
      return Promise.reject(error);
    }
  );

  const clientRetry = axiosRetry(client, {
    retries: 3,
    retryDelay: axiosRetry.exponentialDelay,
    retryCondition: (e) => {
      return e.response && e.response.status === 403;
    },
    onRetry: (retryCount, error, requestConfig) => {
      requestConfig.headers.Authorization = "Bearer " + userX.tokens.token;
      console.log("newHeaderReplace: ", requestConfig.headers);
    },
  });

  const outClient = axios.create();

  function makeHeader(withAuth, addHeader) {
    return {
      ...deffaultHeader,
      ...(withAuth
        ? {
            Authorization: "Bearer " + userX.tokens.token,
          }
        : {}),
      ...addHeader,
    };
  }

  async function Get({
    route,
    withAuth = false,
    addHeader = {},
    external = false,
    params = {},
    localAvailable = false
  }) {
    const cli = external ? outClient : client;
    if(withAuth && !userX.tokens.token){
      return {};
    }
    let resp;
    //check if cachedUrls request is localstorage
   
    const url = new URL(`${import.meta.env.VITE_API_URL}${route}`);
    
    if(route.includes("/me") &&  !navigator.onLine){
      //implement network first cache
      const cacheData = await caches.match(route);
     
      //check cache expiration (8days)
      if(cacheData){
        const response = JSON.parse(await cacheData.text());
        const nextDate = new Date( Number(response.created) + 8 * 24 * 60 * 60 * 1000)
        const daysAvailable = Date.parse(nextDate) - Date.now();
        if(daysAvailable > 0){
          return response;
        }
        //remove outdated cache
        caches.open("eki-cache").then((cache) => {
          cache.delete(route);
        });
      }

    }
    else if(cachedUrls.includes(route)){
      //implement cache first cache
      
      const cacheData = await caches.match(route);
      //statusText will be used to control cache expiration (8days)
      if(cacheData){
        const response = JSON.parse(await cacheData.text());
        const nextDate = new Date( Number(response.created) + 8 * 24 * 60 * 60 * 1000)
        const daysAvailable = Date.parse(nextDate) - Date.now();
        if(daysAvailable > 0){
          return response;
        }
        //remove outdated cache
        caches.open("eki-cache").then((cache) => {
          cache.delete(route);
        });
      }
    }
    else if(route.includes("song-detail") && songsX.allSongs && songsX.allSongs.length > 0){
      if(localAvailable){
        return {
          data: {song:{...songsX.getSongDetail(route.split("/")[2])[0], videoFile:'default-video'}, jwt:null, songHistoryId:null},
         status: 200
       };
      }
    }
    else if(route.includes("song-by-number") && songsX.allSongs && songsX.allSongs.length > 0){
        return {
          data: songsX.getSongByNum(route.split("/")[2])[0],
        status: 200
      };
    }
    else if(route.includes("songs-artista") && songsX.allSongs && songsX.allSongs.length > 0){
      const params = route.split("/");
      return {
        data: {songs: songsX.getSongsByArtista(params[2], params[3], url.searchParams.get('limit'))},
       status: 200
     };
    }
    else if(route.includes("songs-genero") && songsX.allSongs && songsX.allSongs.length > 0){
      const params = route.split("/");
      return {
        data: {songs: songsX.getSongsByGenero(params[2], params[3], url.searchParams.get('limit'))},
       status: 200
     };
    }
    
    else if(route.includes("artista-detail") && artistasX.allArtistas && artistasX.allArtistas.length > 0){
      const artista = artistasX.getArtistaDetail(route.split("/")[2], songsX.allSongs)[0]
      return {
        data: {artista, count:artista.songsCount},
       status: 200
     };
    }
    else if(route.includes("genero-detail") && artistasX.allArtistas && artistasX.allArtistas.length > 0){
      const genero = generosX.getGeneroDetail(route.split("/")[2], songsX.allSongs)[0];
      return {
        data: {genero, count:genero.songsNumber},
       status: 200
     };
    }
    else if(route.includes("generos") && generosX.allGeneros && generosX.allGeneros.length > 0){
      const page = parseInt(route.split("/")[2]);
      return {
         data: {
          generos: generosX.getAllGenerosWithSongsCount(songsX.allSongs,page, url.searchParams.get('limit'))
        },
        status: 200
      };
    }
    else if(route.includes("/get-genres") && generosX.allGeneros && generosX.allGeneros.length > 0){
      return {
         data: {
          generos: generosX.getAllGenerosWithSongsCount(songsX.allSongs,1, url.searchParams.get('limit'))
        },
        status: 200
      };
    }
    else if(route.includes("artistas") && artistasX.allArtistas && artistasX.allArtistas.length > 0){
      const page = parseInt(route.split("/")[2]);
      return {
        data: {
         artistas: artistasX.getAllArtistasWithSongsCount(songsX.allSongs,page, url.searchParams.get('limit'))
       },
       status: 200
     };
    }
    else if(route.includes("/get-artists") && artistasX.allArtistas && artistasX.allArtistas.length > 0){
     
      return {
        data: {
         artistas: artistasX.getAllArtistasWithSongsCount(songsX.allSongs,1, url.searchParams.get('limit'))
       },
       status: 200
     };
    }
    else if(route.includes("/songs") && songsX.allSongs && songsX.allSongs.length > 0){
      const page = parseInt(route.split("/")[2]);
      return {
        data: {
         songs: songsX.getAllSongs(page, url.searchParams.get('limit'), params)
       },
       status: 200
     };
    }
    else if (route.includes("general-search") && songsX.allSongs && songsX.allSongs.length > 0) {
      return {
        data: {
          results: allSongsX.getAllSongs(params)
        },
        status: 200
      }
    }

    else if (route.includes("get-history-by-user") && historyX.allHistory) {
      const page = parseInt(route.split("/")[2]);
      return {
        data: historyX.getData(page, url.searchParams.get('limit'), params),
        status: 200
      }
    }

    else if (route.includes("/playlist/get/") && playlistX.allPlaylist.length > 0) {
      return {
        data: playlistX.getData(),
        status: 200
      }
    }

    else if(route.includes("/downloaded-songs")){
      const page = parseInt(route.split("/")[2]);
      return {
        data: {
         songs: songsX.getAllDownloadedSongs(page, url.searchParams.get('limit'), params, userX.localSongs.map(e=>{
          return parseInt(e.split('.')[0].replace(/\D/g, ""))
         }))
       },
       status: 200
     };
    }
    else if (route.includes("/playlist/detail") && playlistX.allPlaylist) {
      const id = route.split("/")[3];
      return {
        data: playlistX.getDataById(id),
        status: 200
      }
    }
    else if (route.includes("/playlist/get-songs") && playlistX.allPlaylist) {
      const id = route.split("/")[3];
      return {
        data: playlistX.getDataBySongs(id),
        status: 200
      }
    } 
    resp = await cli.get(route, {
      headers: makeHeader(withAuth, addHeader),
      params,
    });
    
    if(cachedUrls.includes(route) || route.includes("/me")){
      //save cachedUrls in cache storage
      caches.open("eki-cache").then(cache=>{
        const cacheResponse = new Response(JSON.stringify({...resp, created:Date.now()}));
        cache.put(route, cacheResponse)
      });
    }
    return resp;
  }

  async function Put({
    route,
    withAuth = false,
    addHeader = {},
    body = {},
    external = false,
  }) {
    const cli = external ? outClient : client;
    return cli.put(route, body, { headers: makeHeader(withAuth, addHeader) });
  }

  async function Post({
    route,
    withAuth = false,
    addHeader = {},
    body = {},
    external = false,
  }) {
    const cli = external ? outClient : client;
    if(navigator.onLine){
      return cli.post(route, body, {
        headers: makeHeader(withAuth, addHeader),
      });
    }
    else{
      if(route.includes('/finish-song')){
        //TODO save history to sync later when there is internet
        const {id, art, gen, title, img, time, num} = await songsX.getSongDetail(body.songId)[0];
        historyX.saveHistory(JSON.parse(JSON.stringify({id, art, gen, title, img, time, num, history:body})))
      }

    }
    
  }

  async function Delete({
    route,
    withAuth = false,
    addHeader = {},
    external = false,
  }) {
    const cli = external ? outClient : client;
    return cli.delete(route, { headers: makeHeader(withAuth, addHeader) });
  }

  const PostNoEncrypt = (route, withAuth = false, body = {}, addHeader = {}) => {
    const head = makeHeader(withAuth, addHeader);
    return fetch(`${import.meta.env.VITE_API_URL}${route}`, {
      method: "POST",
      headers:head,
      body:JSON.stringify(body)
    });

  }

  return { client, clientRetry, outClient, Get, Put, Post, Delete, PostNoEncrypt };
});
