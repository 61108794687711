import { defineStore } from "pinia";
import { useDatabaseStore } from "@/stores/database";
import { useHistoryStore } from "./history";
import { usePlaylistStore } from "./playlists";
export const useOfflineStore = defineStore("offline", () => {
    function syncBasicData() {
        navigator.serviceWorker.ready.then(async () => {
            useDatabaseStore();
        });
    } 
    function syncUserData() {
        navigator.serviceWorker.ready.then(async () => {
            useHistoryStore();
            usePlaylistStore();
        });
    }   
    return {syncBasicData, syncUserData};
});