import { io } from "socket.io-client";
import { defineStore } from "pinia";
import { ref, watch } from "vue";
import { usePlayerStore } from "./player";
import { useUserStore } from "./user";
import { useUtilsStore } from "./utils";

export const useSocketStore = defineStore("socket", () => {
  const storedSocket = JSON.parse(localStorage.getItem("ekiPersistedSocket"));

  const socketOnline = ref(false);
  const controlRoom = ref(storedSocket?.controlRoom ?? null);
  const listeningToControlRoom = ref(false);
  const controlConnected = ref(false);
  const controls = ref([]);
  const mainControl = ref(null);

  const utilsX = useUtilsStore();
  const playerX = usePlayerStore();
  const userX = useUserStore();

  watch(
    () => controlRoom.value,
    (newRoom) => {
      if (newRoom === null) {
        disconnectControl();
      }
      localStorage.setItem(
        "ekiPersistedSocket",
        JSON.stringify({
          controlRoom: newRoom,
        })
      );
    }
  );

  watch(
    [() => userX.isLoggedIn, () => userX.firebaseUser],
    ([isLogged, fbUser]) => {
      console.log(isLogged, fbUser);
      if (isLogged) {
        socket.connect();
      } else {
        socket.disconnect();
      }
    }
  );

  watch(
    () => playerX.controlState,
    (newState) => {
      if (controlConnected.value) {
        console.log("EnviandoA Control");

        emit("roomMessage", {
          type: "player/updateState",
          data: playerX.controlState,
        });
      }
    },
    { deep: true }
  );

  const socket = io(import.meta.env.VITE_BROOM_SOCKET_ENDPOINT, {
    reconnectionDelayMax: 10000,
    transports: ["polling"],
    autoConnect: false,
  });

  socket.on("notification", async ({ type, data }) => {
    console.log(type, data);

    switch (type) {
      case "connected":
        socketOnline.value = true;
        if (controlRoom.value != null) {
          connectControl();
        }
        break;
      case "socket/joinedControlRoom":
        if (controlRoom.value === data) {
          listeningToControlRoom.value = true;
          emit("roomMessage", {
            type: "socket/setPlayerConnected",
            data: {
              sender: socket.id,
              value: true,
            },
          });
        }
        break;
      case "socket/someoneWentOffline":
        if (controls.value.includes(data.id)) {
          const index = controls.value.indexOf(data.id);
          controls.value.splice(index, 1);

          if (controls.value.length === 0) {
            utilsX.setNotif({
              title: "control.remote",
              message: "control.noMoreControls",
              type: "info",
              timeVisible: 5,
              position: "top-center",
            });
            controlConnected.value = false;
            mainControl.value = null;
          } else if (data.id === mainControl.value) {
            mainControl.value = controls.value[0];
            emit("directMessage", {
              type: "socket/setMainControl",
              to: mainControl.value,
              data: true,
            });
          }
        }
        break;
      case "socket/setControlConnected":
        if (!controls.value.includes(data.sender)) {
          if (!controlConnected.value) {
            controlConnected.value = true;
          }
          controls.value.push(data.sender);
          const isMain = mainControl.value === null
          if (isMain) {
            mainControl.value = data.sender;
            utilsX.setNotif({
              title: "control.remote",
              message: "control.newMainControlConnected",
              type: "info",
              timeVisible: 5,
              position: "top-center",
            });
          } else {
            utilsX.setNotif({
              title: "control.remote",
              message: "control.newControlConnected",
              type: "info",
              timeVisible: 5,
              position: "top-center",
            });
          }

          emit("directMessage", {
            type: "socket/setPlayerConnected",
            to: data.sender,
            data: {
              sender: socket.id,
              value: true,
              mainControl: isMain,
            },
          });
          emit("directMessage", {
            type: "player/updateState",
            to: data.sender,
            data: playerX.controlState,
          });
        }
        break;
      case "player/callPlayerFunction":
        playerX[data.functionName](data.param, data.param2);
        break;
      case "player/callPlayerReactions":
        utilsX[data.functionName](data.param, data.param2);
        break;
    }
  });
  socket.on("connect", () => {
    console.log("connect");
  });
  socket.on("disconnect", (reason) => {
    console.log("disconnect", reason);
    socketOnline.value = false;
    listeningToControlRoom.value = false;
    controlConnected.value = false;
  });
  socket.on("connect_error", (error) => {
    console.log("conect", error);
  });
  socket.on("error", (error) => {
    console.log("error", error);
  });
  socket.on("ping", () => {
    console.log("ping");
  });
  socket.on("reconnect", (attempt) => {
    console.log("reconnect", attempt);
  });
  socket.on("reconnect_attempt", (attempt) => {
    console.log("reconnect_attempt", attempt);
  });
  socket.on("reconnect_error", (error) => {
    console.log("reconnect_error", error);
  });
  socket.on("reconnect_failed", () => {
    console.log("reconnect_failed");
  });

  function emit(type, data) {
    socket.emit(type, data);
  }

  function connectControl() {
    if (!listeningToControlRoom.value) {
      if (userX.isLoggedIn) {
        console.log("reconectinControl");
        console.log(controlRoom.value);
        emit("joinControl", controlRoom.value);
      } else {
        console.log("no logged in");
        controlRoom.value = null;
      }
    } else {
      console.log("ya conectado");
    }
  }

  function disconnectControl() {
    socket.disconnect();
    listeningToControlRoom.value = false;
  }

  return {
    socketOnline,
    controlRoom,
    controlConnected,
    listeningToControlRoom,
    controls,
    mainControl,
    emit,
    connectControl,
  };
});
