import { defineStore } from "pinia";
import { ref } from "vue";

export const useBinarySongStore = defineStore("binarySong", () => {
    
    if ('showDirectoryPicker' in window) {
        //OPFS available
        console.log("opfs is availble");
        return;
    }
    const db = ref(null);
    const dbName = "eki-binarySongDB";
    const request = window.indexedDB.open(dbName, 1);
    const allBinarySongs = [];
    request.onupgradeneeded = function(event) {
        const db = event.target.result;
        db.createObjectStore("files", { keyPath: "id" });
    };

    request.onsuccess = function(event) {
        db.value = event.target.result;
        load();
    };

    function saveSongInDB(name, data){
        const transaction = db.value.transaction(["files"], "readwrite");
        const objectStore = transaction.objectStore("files");
        objectStore.put({ id: name, data });
        
    }

    function load(){
        const tx = db.value.transaction(["files"]);
        const store = tx.objectStore('files');
        const allSongs = store.getAll();
        allSongs.onsuccess = function(event) {
            const songs = event.target.result;
            for(const s in songs){
                allBinarySongs.push(songs[s].id);
            }
        }
   }
   async function loadSongData(num){
        return new Promise((resolve) => {
            const tx = db.value.transaction(["files"]);
            const store = tx.objectStore('files');
            const data = store.get(`ECK ${num}.mp3`);
            let file, voiceFile;
            data.onsuccess = function(event) {
                if(event.target.result){
                    file = event.target.result.data;
                }
            }
            const dataVoice = store.get(`${num}.mp3`);
            dataVoice.onsuccess = function(event) {
                if(event.target.result){
                    voiceFile = event.target.result.data;
                }
                
                resolve({file:file?new Blob([file],{type:'application/octet-binary'}):null, voiceFile:voiceFile?new Blob([voiceFile],{type:'application/octet-binary'}):null})
            }
        }); 
   }
   async function removeSongData(num){
        const tx = db.value.transaction(["files"]);
        const store = tx.objectStore('files');
        store.delete(`ECK ${num}.mp3`)
        store.delete(`${num}.mp3`)


   }
   return {saveSongInDB, allBinarySongs, loadSongData, removeSongData}

});